import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IPaidPlansProviderProps } from './interfaces';
import { loadUserPaidPlans } from '../../services/loadPaidPlans';
import { getUserEligiblePlans } from './helpers/getUserEligiblePlans';
import { MEMBERSHIP_APP_ID, PP_SECTION_ID } from '../../editor/app-config';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { userTypeHandlers } from '../User/helpers/userTypeHandlers';
import { WarmupData } from '../../services/WarmupData';

let paidPlans = null;

const WARMUP_DATA_KEY = 'programsPaidPlans';

export async function paidPlansPropsMap(
  flowAPI: ControllerFlowAPI,
): Promise<IPaidPlansProviderProps> {
  const warmupData = new WarmupData(flowAPI);
  const _ppsFromSSR = warmupData.get(WARMUP_DATA_KEY);

  if (paidPlans || _ppsFromSSR) {
    return _ppsFromSSR || paidPlans;
  }

  const userType = (await userProviderPropsMap(flowAPI)).userType;
  const isParticipant = userTypeHandlers.isJoinedAlready(userType);

  if (isParticipant) {
    return {
      userPaidPlans: [],
      eligiblePlans: [],
    };
  }

  const isPPInstalled =
    await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId: MEMBERSHIP_APP_ID,
      sectionId: PP_SECTION_ID,
    });

  const [userPaidPlans, eligiblePlans] = isPPInstalled
    ? await Promise.all([
        loadUserPaidPlans(flowAPI.httpClient),
        getUserEligiblePlans(flowAPI),
      ])
    : [[], []];

  if (userPaidPlans) {
    flowAPI.controllerConfig.wixCodeApi.user.onLogin(() => {
      paidPlans.updatePromise = (async () => {
        paidPlans.eligiblePlans = isPPInstalled
          ? await getUserEligiblePlans(flowAPI)
          : [];
        flowAPI.controllerConfig.setProps(paidPlans);
      })();
    });
  }

  paidPlans = {
    userPaidPlans,
    eligiblePlans,
    updatePromise: Promise.resolve(),
  };

  if (!_ppsFromSSR) {
    warmupData.set(WARMUP_DATA_KEY, {
      userPaidPlans: paidPlans.userPaidPlans,
      eligiblePlans: paidPlans.eligiblePlans,
    });
  }

  flowAPI.controllerConfig.setProps({
    userPaidPlans: paidPlans.userPaidPlans,
    eligiblePlans: paidPlans.eligiblePlans,
  });

  return paidPlans;
}
